import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function HowDoYouGetEmBack({ data }) {
  return (
    <Layout>
      <Helmet title="How Do You Get Em Back | David Ramirez" />

      <h1>How Do You Get Em Back</h1>

      <h2>David Ramirez</h2>

      <h4>Key: E</h4>

      <h4>Tempo: 82</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>E</Ch>I remember being kissed
          </p>
          <p>
            When <Ch>A</Ch>love was just a <Ch>E</Ch>wish
          </p>
          <p>
            <Ch>B</Ch>Time was on our <Ch>C#m</Ch>side
          </p>
          <p>
            <Ch>A</Ch>And death was just a <Ch>B</Ch>myth
          </p>
          <p>
            <Ch>E</Ch>Well I got to wishing
          </p>
          <p>
            And I <Ch>A</Ch>felt the weight of <Ch>E</Ch>time
          </p>
          <p>
            Then <Ch>C#m</Ch>love came a<Ch>B</Ch>round and I <Ch>E</Ch>died
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
          <p>
            When they <Ch>B</Ch>say this is the <Ch>C#m</Ch>last time
          </p>
          <p>
            <Ch>A</Ch>How do you make it <Ch>E</Ch>last
          </p>
          <p>
            When they're <Ch>B</Ch>gone
          </p>
          <p>
            <Ch>A</Ch>How do you fuel the <Ch>E</Ch>fire
          </p>
          <p>
            When they are pouring <Ch>B</Ch>water on the <Ch>C#m</Ch>wood
          </p>
          <p>
            <Ch>A</Ch>How do you get 'em
          </p>
          <p>
            <Ch>B</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
        </Chorus>
        <Verse>
          <p>
            If you got a <Ch>E</Ch>lover who failed you
          </p>
          <p>
            Don't <Ch>A</Ch>mean they're worth <Ch>E</Ch>leaving
          </p>
          <p>
            Some <Ch>B</Ch>lovers would never <Ch>C#m</Ch>hurt you
          </p>
          <p>
            Don't <Ch>A</Ch>mean they're worth <Ch>B</Ch>keeping
          </p>
          <p>
            I ain't <Ch>A</Ch>saying I was <Ch>E</Ch>right
          </p>
          <p>
            But I <Ch>B</Ch>know we ain't <Ch>C#m</Ch>wrong
          </p>
          <p>
            And I am <Ch>A</Ch>breaking my <Ch>B</Ch>back to get you <Ch>E</Ch>
            home
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
          <p>
            When they <Ch>B</Ch>say this is the <Ch>C#m</Ch>last time
          </p>
          <p>
            <Ch>A</Ch>How do you make it <Ch>E</Ch>last
          </p>
          <p>
            When they're <Ch>B</Ch>gone
          </p>
          <p>
            <Ch>A</Ch>How do you fuel the <Ch>E</Ch>fire
          </p>
          <p>
            When they are pouring <Ch>B</Ch>water on the <Ch>C#m</Ch>wood
          </p>
          <p>
            <Ch>A</Ch>How do you get 'em
          </p>
          <p>
            <Ch>B</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
        </Chorus>
        <Bridge>
          <p>
            And I'm <Ch>A</Ch>losing my mind<Ch>B</Ch>
          </p>
          <p>
            Cause I'm <Ch>C#m</Ch>losing my girl
          </p>
          <p>
            She's a <Ch>A</Ch>delicate flower<Ch>B</Ch> in a desol<Ch>C#m</Ch>
            ate world
          </p>
          <p>
            And I'm <Ch>A</Ch>losing my mind<Ch>B</Ch>
          </p>
          <p>
            Cause I'm <Ch>C#m</Ch>losing my girl
          </p>
          <p>
            She's a <Ch>A</Ch>delicate flower<Ch>B</Ch> in a desol<Ch>C#m</Ch>
            ate world
          </p>
        </Bridge>
        <Chorus>
          <p>
            <Ch>A</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
          <p>
            When they <Ch>B</Ch>say this is the <Ch>C#m</Ch>last time
          </p>
          <p>
            <Ch>A</Ch>How do you make it <Ch>E</Ch>last
          </p>
          <p>
            When they're <Ch>B</Ch>gone
          </p>
          <p>
            <Ch>A</Ch>How do you fuel the <Ch>E</Ch>fire
          </p>
          <p>
            When they are pouring <Ch>B</Ch>water on the <Ch>C#m</Ch>wood
          </p>
          <p>
            <Ch>A</Ch>How do you get 'em
          </p>
          <p>
            <Ch>B</Ch>How do you get 'em <Ch>E</Ch>back
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
